import { cn } from "@/lib/utils";
import { Icon } from "./Icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui";
import { Switch } from "./ui/switch";

const PreferenceSwitch = (props: {
  className?: string;
  icon?: string;
  label: string;
  helperText?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}) => {
  return (
    <div
      className={cn(
        `flex items-center justify-between w-full`,
        props.className,
      )}
    >
      <div className="flex items-center gap-x-2">
        {props.icon && <Icon icon={props.icon} iconStyle="LIGHT" />}
        <span>{props.label}</span>
        {props.helperText && (
          <Tooltip>
            <TooltipTrigger>
              <Icon icon="fa-circle-info" className="text-body-text-subtle" />
            </TooltipTrigger>
            <TooltipContent>{props.helperText}</TooltipContent>
          </Tooltip>
        )}
      </div>
      <Switch checked={props.checked} onCheckedChange={props.onChange} />
    </div>
  );
};

export { PreferenceSwitch };
