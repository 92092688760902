//#region
import { axios } from "@/axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  useParentSamplesByAudit,
  useSampleById,
  useSamplesByAudit,
} from "@/hooks/sample-api";
import {
  Asset,
  Audit,
  Record,
  Sample,
  SampleIdentification,
  SampleRow,
} from "@/types";

import { ApplyResultsFileField } from "./ApplySampleResultsForm";
import { FormFieldWrapper, FormTextInput } from "./DynamicForm";
import {
  SampleResultMultiSelect,
  SampleResultMultiSelectProps,
} from "./SampleResultMultiSelect";
import {
  Button,
  ButtonIconLeft,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadioGroup,
  RadioGroupItem,
  Separator,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui";

//#endregion

type SampleFormType = SampleIdentification | "Link" | "Previously sampled";

export const SampleSheet = ({
  auditId,
  recordId,
  assetId,
  onDone,
}: {
  auditId: number;
  recordId: number;
  assetId: string;
  onDone: (value: Sample) => void;
}) => {
  const [isNewSheetVisible, setNewSheetVisible] = useState(false);
  const [sampleType, setSampleType] = useState<SampleFormType | null>(null);

  const openSheet = (sampleType: SampleFormType) => {
    setSampleType(sampleType);
    setNewSheetVisible(true);
  };
  return (
    <Sheet open={isNewSheetVisible} onOpenChange={setNewSheetVisible}>
      <SheetTrigger asChild>
        <div className="flex flex-row space-x-2">
          <ButtonIconLeft
            type="button"
            icon={"fa-eye"}
            onClick={() => openSheet("Visual assessment")}
          >
            Visual
          </ButtonIconLeft>
          <ButtonIconLeft
            type="button"
            icon={"fa-link"}
            onClick={() => openSheet("Link")}
          >
            Link
          </ButtonIconLeft>
          <ButtonIconLeft
            type="button"
            icon={"fa-microscope"}
            onClick={() => openSheet("Sampled")}
          >
            Sample
          </ButtonIconLeft>
        </div>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>New Sample</SheetTitle>
        </SheetHeader>
        <Separator className="my-4" />
        <SampleForm
          sampleType={sampleType}
          auditId={auditId}
          recordId={recordId}
          assetId={assetId}
          onDone={(newSample) => {
            setNewSheetVisible(false);
            onDone(newSample);
          }}
        />
      </SheetContent>
    </Sheet>
  );
};

type CommonSampleFormProps = {
  auditId: Audit["audit_id"];
  onDone: (value: Sample) => void;
};

type CreateSampleProps = CommonSampleFormProps & {
  sampleType: SampleIdentification | "Link" | "Previously sampled" | null;
  recordId: Record["id"];
  assetId: Asset["asset_id"];
  sample?: never;
};

type UpdateSampleProps = CommonSampleFormProps & {
  sample: SampleRow;
  sampleType?: never;
  recordId?: never;
  assetId?: never;
};

type SampleFormProps = CreateSampleProps | UpdateSampleProps;

export const SampleForm = (props: SampleFormProps) => {
  const { data: sample, refetch } = useSampleById({
    sampleId: props.sample?.as_sampleids,
  });

  const identified = props.sampleType
    ? props.sampleType
    : sample?.parent_id
      ? "Link"
      : sample?.identified;

  if (!identified) {
    return null;
  }

  const onDone = (sample: Sample) => {
    refetch();
    props.onDone(sample);
  };

  const savePhysicalSample = async (data: PhysicalSampleFormData) => {
    if (data.file.file_type === "new" && data.file.new_file) {
      const formData = new FormData();
      formData.append("identified", data.identified);
      formData.append("asset_id", String(props.assetId));
      formData.append("inspection_id", String(props.recordId));
      formData.append("barcode", data.barcode);
      formData.append("lab_cert_number", data.lab_cert_number || "");
      formData.append("type", data.type || "");
      data.result_ids?.forEach((id) => {
        formData.append("result_ids[]", id.toString());
      });
      formData.append(
        "certificate_of_analysis_file",
        data.file.new_file[0],
        data.file.new_file[0].name,
      );

      // update API call
      if (props.sample) {
        formData.append("_method", "PUT");
        const response = await axios.post(
          route("api.samples.update", { sample: props.sample.as_sampleids }),
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          },
        );
        onDone(response.data as Sample);
        return;
      }
      // else create API call
      const response = await axios.post(route("api.samples.store"), formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      onDone(response.data as Sample);
      return;
    }

    // remove result_ids from submit data if result_ids.length === 0
    const { result_ids, ...rest } = data;
    const submitJson = {
      ...rest,
      ...(result_ids?.length === 0 ? {} : { result_ids }),
    };

    // send file_id via json
    if (props.sample) {
      const response = await axios.put(
        route("api.samples.update", { sample: props.sample.as_sampleids }),
        {
          ...sample,
          ...submitJson,
          certificate_of_analysis_file_id: data.file.file_id,
        },
      );
      onDone(response.data as Sample);
      return;
    }
    const response = await axios.post(route("api.samples.store"), {
      ...submitJson,
      asset_id: props.assetId,
      inspection_id: props.recordId,
      certificate_of_analysis_file_id: data.file.file_id,
    });
    onDone(response.data as Sample);
  };

  const saveSample = async (data: SampleFormData) => {
    if (props.sample) {
      const response = await axios.put(
        route("api.samples.update", { sample: props.sample.as_sampleids }),
        {
          ...sample,
          ...data,
        },
      );
      onDone(response.data as Sample);
      return;
    }
    const response = await axios.post(route("api.samples.store"), {
      ...data,
      asset_id: props.assetId,
      inspection_id: props.recordId,
    });
    onDone(response.data as Sample);
  };

  const renderSampleForm = () => {
    switch (identified) {
      case "Sampled":
      case "Previously sampled":
        return (
          <PhysicalSampleForm
            auditId={props.auditId}
            onSubmit={savePhysicalSample}
            initialValues={
              sample && {
                ...sample,
                result_score: String(sample.result_score),
                parent_id: null,
                result_ids: sample.result_ids?.map((r) => String(r)),
                file: sample.certificate_of_analyses?.length
                  ? {
                      file_type: "old",
                      file_id: String(sample.certificate_of_analyses[0].id),
                    }
                  : { file_type: "none" },
              }
            }
          />
        );
      case "Visual assessment":
        return (
          <VisualSampleForm
            auditId={props.auditId}
            onSubmit={saveSample}
            initialValues={
              sample && {
                ...sample,
                result_score: String(sample.result_score),
                parent_id: null,
                result_ids: sample.result_ids?.map((r) => String(r)),
              }
            }
          />
        );
      case "Link":
        return (
          <LinkSampleForm
            auditId={props.auditId}
            onSubmit={saveSample}
            initialValues={
              sample && {
                ...sample,
                parent_id: String(sample.parent_id),
                result_score: String(sample.result_score),
                result_ids: sample.result_ids?.map((r) => String(r)),
              }
            }
          />
        );
      default:
        return null;
    }
  };

  return <div>{renderSampleForm()}</div>;
};

const sampleFormSchema = z.object({
  identified: z.enum(["Sampled", "Visual assessment"]),
  barcode: z.string({ required_error: "Sample ID/ Barcode is required" }),
  lab_cert_number: z.string().nullable().optional(),
  type: z.string().nullable().optional(),
  result_ids: z.array(z.string()).nullable().optional(),
  result_score: z.string().nullable().optional(),
  offline_sample_id: z.string().optional(),
  inspection_id: z.number().nullable().optional(),
  archived: z.number().nullable().optional(),
  parent_id: z.string().nullable(),
});

type SampleFormData = z.infer<typeof sampleFormSchema>;

type SampleFormBaseProps = {
  auditId: Audit["audit_id"];
  initialValues?: SampleFormData;
  onSubmit: (values: SampleFormData) => Promise<void>;
};
const physicalSampleTypeOptions = [
  { label: "Bulk", value: "Bulk" },
  { label: "Surface", value: "Surface" },
];

type PhysicalSampleFormData = SampleFormData & {
  file: {
    file_type: "new" | "old" | "none";
    file_id?: string;
    new_file?: File[];
  };
};

const PhysicalSampleForm = (
  props: Omit<SampleFormBaseProps, "onSubmit" | "initialValues"> & {
    initialValues?: PhysicalSampleFormData;
    onSubmit: (values: PhysicalSampleFormData) => Promise<void>;
  },
) => {
  const { data: allSamples } = useSamplesByAudit({ auditId: props.auditId });
  const form = useForm<PhysicalSampleFormData>({
    values: props.initialValues || {
      identified: "Sampled",
      barcode: "",
      parent_id: null,
      file: { file_type: "none" },
    },
  });

  useEffect(() => {
    if (allSamples && form.getValues("barcode") === "") {
      form.setValue("barcode", getNextBarCode(allSamples, "Sampled"));
    }
  }, [allSamples, form]);

  if (!allSamples) {
    return null;
  }

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit(props.onSubmit)(e);
        }}
        className="space-y-6"
      >
        <FormFieldWrapper
          fieldInfo={{
            label: "Sample ID/Barcode",
            name: "barcode",
            accessorKey: "",
            type: { input: "TEXT" },
          }}
        >
          <FormTextInput
            name={"barcode"}
            label="Sample ID/Barcode"
            accessorKey=""
          />
        </FormFieldWrapper>
        <FormField
          control={form.control}
          name={"type"}
          defaultValue="Bulk"
          render={({ field }) => {
            return (
              <FormItem className="flex flex-col">
                <FormControl>
                  <RadioGroup
                    onValueChange={(value: string) => {
                      field.onChange(value);
                      const barcode = form.getValues("barcode");
                      if (
                        barcode.match(/^S-\d+-.*$/) &&
                        !form.formState.dirtyFields.barcode
                      ) {
                        switch (value) {
                          case "Bulk":
                            form.setValue(
                              "barcode",
                              `S-${barcode.split("-")[1]}-B`,
                            );
                            break;
                          case "Surface":
                            form.setValue(
                              "barcode",
                              `S-${barcode.split("-")[1]}-S`,
                            );
                            break;

                          default:
                            break;
                        }
                      }
                    }}
                    value={field.value || undefined}
                    className="grid grid-cols-2 gap-4"
                  >
                    {physicalSampleTypeOptions.map((option) => {
                      return (
                        <FormItem key={option.value}>
                          <FormLabel className="flex items-center space-x-2 bg-secondary-50 rounded-md px-4 py-2 [&:has([data-state=checked])]:bg-primary-100">
                            <FormControl>
                              <RadioGroupItem value={option.value} />
                            </FormControl>
                            <span>{option.label}</span>
                          </FormLabel>
                        </FormItem>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="result_ids"
          defaultValue={[]}
          render={({ field }) => {
            return (
              <FormItem className="flex flex-col">
                <FormLabel>Results</FormLabel>
                <SampleResultMultiSelect
                  value={
                    field.value?.map((v) =>
                      Number(v),
                    ) as SampleResultMultiSelectProps["value"]
                  }
                  onChange={field.onChange}
                />
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormFieldWrapper
          fieldInfo={{
            label: "CoA / Lab cert #",
            name: "lab_cert_number",
            accessorKey: "lab_cert_number",
            type: { input: "TEXT" },
          }}
        >
          <FormTextInput
            name={"lab_cert_number"}
            label="CoA / Lab cert #"
            accessorKey=""
            defaultValue={""}
          />
        </FormFieldWrapper>
        <ApplyResultsFileField name="file" />
        <FormMessage>{form.formState.errors.root?.message}</FormMessage>
        <div className="text-end">
          {!form.formState.isSubmitting ? (
            <Button type="submit">Save</Button>
          ) : (
            <Button type="button" disabled>
              Please wait...
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
};

const visualSampleOptions = [
  {
    label: "Presumed Asbestos",
    value: "7",
    score: "3",
  },
  {
    label: "Presumed No Asbestos",
    value: "10",
    score: "1",
  },
  {
    label: "No Suspect Material",
    value: "11",
    score: "0",
  },
];
const VisualSampleForm = (props: SampleFormBaseProps) => {
  const { data: allSamples } = useSamplesByAudit({ auditId: props.auditId });
  const form = useForm<SampleFormData>({
    defaultValues: props.initialValues || { identified: "Visual assessment" },
  });

  if (!allSamples) {
    return null;
  }

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit(props.onSubmit)(e);
        }}
        className="space-y-6"
      >
        <FormFieldWrapper
          fieldInfo={{
            label: "Sample ID/Barcode",
            name: "barcode",
            accessorKey: "",
            type: { input: "TEXT" },
          }}
        >
          <FormTextInput
            name={"barcode"}
            label="Sample ID/Barcode"
            defaultValue={getNextBarCode(allSamples, "Visual assessment")}
            accessorKey=""
          />
        </FormFieldWrapper>
        <FormField
          control={form.control}
          name={"result_ids.0"}
          defaultValue="7"
          render={({ field }) => {
            return (
              <FormItem className="flex flex-col">
                <FormControl>
                  <RadioGroup
                    onValueChange={(value: string) => {
                      field.onChange(value);
                      const barcode = form.getValues("barcode");
                      if (
                        barcode.match(/^VA-\d+-.*$/) &&
                        !form.formState.dirtyFields.barcode
                      ) {
                        switch (value) {
                          case "7":
                            form.setValue(
                              "barcode",
                              `VA-${barcode.split("-")[1]}-P`,
                            );
                            break;
                          case "10":
                            form.setValue(
                              "barcode",
                              `VA-${barcode.split("-")[1]}-PNA`,
                            );
                            break;
                          case "11":
                            form.setValue(
                              "barcode",
                              `VA-${barcode.split("-")[1]}-NSM`,
                            );
                            break;
                          default:
                            break;
                        }
                      }
                    }}
                    value={field.value || undefined}
                    className="grid grid-cols-2 gap-4"
                  >
                    {visualSampleOptions.map((option) => {
                      return (
                        <FormItem key={option.value}>
                          <FormLabel className="flex items-center space-x-2 bg-secondary-50 rounded-md px-4 py-2 [&:has([data-state=checked])]:bg-primary-100">
                            <FormControl>
                              <RadioGroupItem value={option.value} />
                            </FormControl>
                            <span>{option.label}</span>
                          </FormLabel>
                        </FormItem>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormMessage>{form.formState.errors.root?.message}</FormMessage>
        <div className="text-end">
          {!form.formState.isSubmitting ? (
            <Button type="submit">Save</Button>
          ) : (
            <Button type="button" disabled>
              Please wait...
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
};

const LinkSampleForm = (props: SampleFormBaseProps) => {
  const parentSamples = useParentSamplesByAudit({
    auditId: props.auditId,
  });

  const form = useForm<{ parent_id: string }>({
    defaultValues: props.initialValues as { parent_id: string },
  });

  if (!parentSamples) {
    return null;
  }

  if (parentSamples.length === 0) {
    return (
      <p className="text-muted-foreground text-sm text-center text-balance">
        You can only link to samples within an audit.
      </p>
    );
  }

  const onSubmit = (values: { parent_id: string }) => {
    const sample = parentSamples.find(
      (s) => s.sample_id === Number(values.parent_id),
    );
    if (sample) {
      props.onSubmit({
        parent_id: String(sample.sample_id),
        identified: sample.identified,
        result_score: String(sample.result_score),
        barcode: sample.barcode,
        lab_cert_number: sample.lab_cert_number || "",
      });
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit(onSubmit)(e);
        }}
        className="space-y-6"
      >
        <FormField
          control={form.control}
          name={"parent_id"}
          defaultValue=""
          render={({ field }) => {
            return (
              <FormItem className="flex flex-col">
                <FormLabel>Please choose a sample</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={(value: string) => {
                      field.onChange(value);
                    }}
                    value={field.value}
                    className="grid grid-cols-1 gap-4"
                  >
                    {parentSamples.map((option) => {
                      return (
                        <FormItem key={option.sample_id}>
                          <FormLabel className="flex items-center space-x-2 bg-secondary-50 rounded-md px-4 py-2 [&:has([data-state=checked])]:bg-primary-100">
                            <FormControl>
                              <RadioGroupItem
                                value={String(option.sample_id)}
                              />
                            </FormControl>
                            <span>{option.barcode}</span>
                          </FormLabel>
                        </FormItem>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormMessage>{form.formState.errors.root?.message}</FormMessage>
        <div className="text-end">
          {!form.formState.isSubmitting ? (
            <Button type="submit">Save</Button>
          ) : (
            <Button type="button" disabled>
              Please wait...
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
};

export const getNextBarCode = (
  samples: Sample[],
  assessment: "Sampled" | "Visual assessment",
) => {
  const regex = assessment === "Sampled" ? /^S-\d+-.*$/ : /^VA-\d+-.*$/;
  const parentSamples = samples.filter((s) => !s.parent_id);
  const samplesWithOrderedBarcode = parentSamples.filter((s) =>
    s.barcode?.match(regex),
  );
  const sorted = samplesWithOrderedBarcode.sort((a, b) => {
    return Number(b.barcode.split("-")[1]) - Number(a.barcode.split("-")[1]);
  });
  const highestBarcode = sorted[0];
  const nextBarcodeNumber = highestBarcode
    ? String(Number(highestBarcode.barcode.split("-")[1]) + 1).padStart(2, "0")
    : "01";

  return assessment === "Sampled"
    ? `S-${nextBarcodeNumber}-B`
    : `VA-${nextBarcodeNumber}-P`;
};
