import { Link } from "@inertiajs/react";

import { Audit, AuditRow } from "@/types";

import { ALIGN_OPTIONS } from "@radix-ui/react-popper";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./ui/hover-card";
import { cn } from "@/lib/utils";

export const AuditAssetMenu = (props: {
  audit: AuditRow | Audit;
  href?: string;
  align?: (typeof ALIGN_OPTIONS)[number];
  side?: "bottom" | "top" | "right" | "left" | undefined;
  sideOffset?: number;
  className?: string;
}) => {
  const firstAsset = props.audit.assets[0]?.asset_display_name || "-";
  const additionalAssets =
    props.audit.assets_count > 1 ? ` +${props.audit.assets_count - 1}` : "";

  return (
    <>
      <HoverCard openDelay={100} closeDelay={300}>
        <HoverCardTrigger href={props.href}>
          <div
            className={cn(
              "inline-flex gap-x-3 items-center text-wrap max-w-72",
              props.className,
            )}
          >
            <span className="truncate">{firstAsset}</span>
            <span className="text-body-text-x-subtle">{additionalAssets}</span>
          </div>
        </HoverCardTrigger>
        {props.audit.assets_count > 1 && (
          <HoverCardContent
            align={props.align || "start"}
            side={props.side || "right"}
            sideOffset={props.sideOffset || 4}
            className="w-full rounded flex flex-col max-h-96 overflow-y-auto gap-y-0.5 p-1"
          >
            {props.audit.assets.map((item) => (
              <AssetAuditLink
                key={item.asset_id}
                href={route("asset.show", {
                  asset: item.asset_route,
                  page: "overview",
                })}
                label={item.asset_display_name}
              />
            ))}
          </HoverCardContent>
        )}
      </HoverCard>
    </>
  );
};

const AssetAuditLink = (props: { href: string; label: string }) => {
  return (
    <div className="flex">
      <Link
        href={props.href}
        className="flex h-full w-full hover:bg-accent rounded"
      >
        <span className="text-body-text-subtle hover:text-body-text-default text-sm px-2 py-1.5">
          {props.label}
        </span>
      </Link>
    </div>
  );
};
