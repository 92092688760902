import { config } from "@/lib/constants";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "./ui/button";
import { ImageIcon } from "lucide-react";
import { TODO } from "@/types";
import { Progress } from "./ui/progress";
import { router } from "@inertiajs/react";
import { Icon } from "./Icon";
import { DeletePhoto } from "./PhotoActions";

type RemoteFile = Record<string, unknown> & {
  url: string;
  preview?: never;
  file?: never;
  status?: never;
};

export type LocalFile = Record<string, unknown> & {
  url?: string;
  file: File;
  preview: string;
  status: "UPLOADING" | "UPLOADED" | "ERROR";
};

export type AnyFile = RemoteFile | LocalFile | string;

const isLocalFile = (file: AnyFile): file is LocalFile => {
  return typeof file !== "string" && !!file.preview;
};

const getFileUrl = (file: AnyFile): string => {
  if (isLocalFile(file)) {
    return file.preview;
  }
  if (typeof file === "string") {
    return file;
  }
  return file.url;
};

const getFileType = (file: AnyFile) => {
  if (isLocalFile(file)) {
    if (file.file.type.startsWith("image/")) {
      return "image";
    }
    if (file.file.type.startsWith("application/pdf")) {
      return "pdf";
    }
    return "file";
  }
  const url = getFileUrl(file);
  const imagePattern = new RegExp(
    "^https?:\\/\\/.+\\.(png|jpg|jpeg|bmp|gif|webp)$",
    "i",
  );
  if (imagePattern.test(url)) {
    return "image";
  }
  if (url.startsWith("data:image")) {
    return "image";
  }
  const pdfPattern = new RegExp("^https?:\\/\\/.+\\.(pdf)$", "i");
  if (pdfPattern.test(url)) {
    return "pdf";
  }
  return "file";
};

export type UploadInputBasicProps = {
  uploadParams: { upload_id: number } & Record<string, unknown>;
  accept?: string[];
  maxFiles?: number;
  maxSizeInMB?: number;
  uploadURL?: string;
};

type UploadInputProps = UploadInputBasicProps & {
  files: AnyFile[];
  onChange: (files: AnyFile[]) => void;
  renderAction?: (file: AnyFile) => React.ReactNode;
};

export const UploadInput = ({
  files,
  onChange,
  uploadParams,
  accept = ["image/*"],
  maxFiles = Infinity,
  maxSizeInMB = config.MAX_MB_UPLOAD.DEFAULT,
  uploadURL = route("api.uploads"),
  renderAction,
}: UploadInputProps) => {
  const maxSize = maxSizeInMB * 1024 * 1024;

  const onDrop = useCallback(
    <T extends File>(acceptedFiles: T[]) => {
      const newFiles: AnyFile[] = acceptedFiles?.map((acceptedFile) => {
        return {
          file: acceptedFile,
          preview: URL.createObjectURL(acceptedFile),
          status: "UPLOADING",
        };
      });
      onChange([...files, ...newFiles]);
    },
    [files, onChange],
  );

  const onUploadComplete = useCallback(
    (uploadResponse: UploadResponse, index: number) => {
      const newFiles: AnyFile[] = files.map((file, i) => {
        if (i === index) {
          const localFile = file as LocalFile;
          return {
            ...localFile,
            ...uploadResponse,
            status: "UPLOADED",
          };
        }
        return file;
      });
      onChange(newFiles);
    },
    [onChange, files],
  );

  const removeFile = (index: number) => {
    const nextFiles = files.filter((file, i) => i !== index);
    onChange(nextFiles);
  };

  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    accept: accept.reduce(
      (acc, type) => {
        acc[type] = [];
        return acc;
      },
      {} as Record<string, string[]>,
    ),
    maxSize,
    disabled: files.length >= maxFiles || false,
  });

  const isFileTooLarge = fileRejections?.[0]?.file.size > maxSize;

  return (
    <div
      {...getRootProps({
        className: "w-full border-2 border-dashed rounded-lg",
      })}
    >
      <input {...getInputProps()} />
      <div className="h-full grid grid-cols-3 p-1 gap-2">
        {files?.map((file, index) => {
          if (isLocalFile(file) && file.status !== "UPLOADED") {
            return (
              <div key={String(index)} className="relative">
                <UploadPreview
                  index={index}
                  localFile={file}
                  uploadParams={uploadParams}
                  uploadURL={uploadURL}
                  onUploadComplete={onUploadComplete}
                />
              </div>
            );
          }
          return (
            <FilePreview
              key={String(index)}
              file={file}
              renderAction={
                renderAction ||
                (() => (
                  <div className="absolute top-2 right-2">
                    <DeletePhoto
                      asDropdown={false}
                      onDelete={() => removeFile(index)}
                    />
                  </div>
                ))
              }
            />
          );
        })}
        {files.length < maxFiles && (
          <div className={files.length === 0 ? "col-start-2" : "col-auto"}>
            <div className="h-full flex flex-col items-center justify-center">
              <ImageIcon className="h-8 w-8" />
              <div className={"text-sm text-muted-foreground"}>
                Max {maxSizeInMB}MB
              </div>
              <Button
                type="button"
                className="mt-4"
                variant="secondary"
                onClick={open}
              >
                Select
              </Button>
              {isFileTooLarge && (
                <div className="text-[0.8rem] font-medium text-destructive mt-2">
                  File is too large.
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

type UploadResponse = {
  url: string;
} & Record<string, string>;

const UploadPreview = ({
  index,
  localFile,
  uploadParams,
  uploadURL,
  onUploadComplete,
  onUploadError,
}: {
  index: number;
  localFile: LocalFile;
  uploadParams: Record<string, unknown>;
  uploadURL: string;
  onUploadComplete: (data: UploadResponse, index: number) => void;
  onUploadError?: (error: Error) => void;
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadRef = useRef<XMLHttpRequest | null>(null);

  const xhrOnload = useCallback(() => {
    const status = uploadRef.current?.status;
    const response = uploadRef.current?.response;

    if ((!String(status).startsWith("2") || !response) && onUploadError) {
      onUploadError(
        new Error(`Failed to upload photo, ${uploadRef.current?.status}`),
      );
      return;
    }
    onUploadComplete(response, index);
    uploadRef.current = null;
  }, [index, onUploadComplete, onUploadError]);

  const startUpload = useCallback(async () => {
    if (uploadRef.current) {
      uploadRef.current.onload = xhrOnload;
      return;
    }
    const formData = new FormData();
    formData.append("file", localFile.file);
    Object.entries(uploadParams).forEach(([key, value]) => {
      formData.append(key, String(value));
    });
    uploadRef.current = new XMLHttpRequest();
    uploadRef.current.responseType = "json";
    uploadRef.current.open("POST", uploadURL);
    uploadRef.current.upload.addEventListener(
      "progress",
      ({ loaded, total }) => {
        setUploadProgress((loaded * 100) / total);
      },
    );
    uploadRef.current.send(formData);
    uploadRef.current.onload = xhrOnload;
    uploadRef.current.onerror = async () => {
      const error = uploadRef.current?.response;
      if (onUploadError) {
        onUploadError(error);
      }
    };
  }, [localFile, onUploadError, uploadParams, uploadURL, xhrOnload]);

  const fileStatus = localFile.status;
  useEffect(() => {
    startUpload();
  }, [startUpload]);

  useEffect(() => {
    const beforeUnloadHandler = (event: TODO) => {
      // Recommended
      event.preventDefault();
      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;
    };
    if (fileStatus === "UPLOADING") {
      window.addEventListener("beforeunload", beforeUnloadHandler);
      const removeStartEventListener = router.on("before", (event) => {
        if (!confirm("Upload in progress. Are you sure you want to leave?")) {
          return event.preventDefault();
        }
        // TODO: implement cancel upload logic here
      });
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
        removeStartEventListener();
      };
    }
  }, [fileStatus]);

  return (
    <div className="w-full aspect-1 bg-gray-900">
      <FilePreview file={localFile} />
      {fileStatus === "UPLOADING" && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Progress value={uploadProgress} className="w-[60%]" />
        </div>
      )}
    </div>
  );
};

const FilePreview = ({
  file,
  renderAction,
}: {
  file: AnyFile;
  renderAction?: (file: AnyFile) => React.ReactNode;
}) => {
  const isLocal = isLocalFile(file);
  const url = getFileUrl(file);
  const fileType = getFileType(file);

  console.log({ isLocal, url, fileType });

  const renderPreview = () => {
    switch (fileType) {
      case "image":
        return (
          <img className="h-full w-full object-contain" src={url} alt={""} />
        );
      case "pdf":
        return (
          <div className="h-full w-full flex items-center justify-center">
            <Icon icon="fa-file-pdf text-white" className="text-4xl" />
            {typeof file !== "string" && (
              <p className="absolute bottom-1 inset-x-2 text-white text-sm truncate">
                {isLocal ? file.file.name : (file.filename as string)}
              </p>
            )}
          </div>
        );
      default:
        return (
          <div className="h-full w-full flex items-center justify-center">
            <Icon icon="fa-file text-white" className="text-4xl" />
          </div>
        );
    }
  };

  return (
    <div key={url} className="w-full aspect-1 bg-gray-900 relative group">
      {renderPreview()}
      {renderAction && renderAction(file)}
    </div>
  );
};
