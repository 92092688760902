import { Asset } from "./asset-types";

export const AuditStatus = {
  0: "Planned",
  1: "Scheduled",
  2: "In progress",
  3: "Awaiting results",
  4: "Pending review",
  5: "Completed",
} as const;

export type AuditStatusId = keyof typeof AuditStatus;
export type AuditStatusValue = (typeof AuditStatus)[AuditStatusId];

export type Audit = {
  route_key: string;
  audit_id: number;
  assets: Asset[];
  assets_count: number;
  records_count: number;
  form_id: number;
  job_number: string | null;
  wo_number: string;
  scheduled_date: string;
  audit_status_id: AuditStatusId;
  adhoc: number;
  assigned_user_id: number;
  assigned_contractor_company_id: number;
  offline_audit_id: string | null;
  survey_included: string | null;
  survey_excluded: string | null;
  intrusiveness_level: string;
  archived: number;
  enabled: number;
  settings: Settings | null;
  info: string;
  created_at: string;
  created_by: number;
  created_by_name: string;
  updated_at: string;
  updated_by: number;
  updated_by_name: string;
  migrated: number;
  compliance_type: string;
  inspector: Inspector;
  contractor: Contractor;
  completed_date: string;
};

export type Contractor = {
  value: number;
  label: string;
};

export type Inspector = {
  value: number;
  label: string;
};

export type AuditRow = Audit & {
  notes_count: number;
  auditassets_count: number;
};

export type Settings = {
  audittype: string;
  recordfilter: Recordfilter;
};

export type Recordfilter = {
  all: string;
  confirmed: string;
  presumed: string;
  nosuspect: string;
  nophotos: string;
  custom: string;
};

export type AuditDetails = AuditRow & {
  assets: Asset[];
  status_history: AuditStatusHistory[];
};

export type AuditStatusHistory = {
  status: AuditStatusId;
  actor: string;
  updated_at: string;
};
