import { Icon } from "./Icon";
import { confirm } from "@/components/ConfirmDialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "./ui";
import { axios } from "@/axios";
import { toast } from "sonner";
import { useState } from "react";
import { AnyFile } from "./UploadInput";

type PhotoActionsProps = {
  file: AnyFile & { photo_id: number; primary_photo: boolean };
  onSetAsPrimary: (photoId: number) => void;
  onDelete: () => void;
  asDropdown?: boolean;
};

const DeletePhoto = (
  props: Pick<PhotoActionsProps, "onDelete"> & { asDropdown?: boolean },
) => {
  const handleDelete = async () => {
    const didConfirm = await confirm({
      title: "Delete photo",
      description: "Are you sure you want to delete this photo?",
      confirmButtonText: "Delete",
      theme: "destructive",
    });

    if (didConfirm) {
      props.onDelete();
    }
  };
  if (props.asDropdown) {
    return (
      <DropdownMenuItem
        onClick={handleDelete}
        className="flex items-center gap-2"
      >
        <Icon icon={"fa-trash"} />
        <span>Delete</span>
      </DropdownMenuItem>
    );
  }
  return (
    <IconButton
      type="button"
      variant="danger"
      icon="fa-trash"
      onClick={handleDelete}
    />
  );
};

const SetAsPrimary = (
  props: Omit<PhotoActionsProps, "index" | "onDelete" | "removeFile">,
) => {
  const { file, onSetAsPrimary } = props;
  const shouldBeDisabled = !!file.primary_photo;

  const handleSetPrimaryPhoto = async (photoId: number) => {
    try {
      await axios.put(route("api.assetphotos.update", { photo: photoId }), {
        primary_photo: true,
      });
      toast.success("Primary photo updated");

      onSetAsPrimary(photoId);
    } catch (error) {
      toast.error("Unable to set primary photo");
    }
  };

  if (props.asDropdown) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <DropdownMenuItem
            onClick={() => handleSetPrimaryPhoto(file.photo_id)}
            disabled={shouldBeDisabled}
            className="flex items-center gap-2"
          >
            <Icon icon={"fa-star"} />
            <span>Set as primary photo</span>
          </DropdownMenuItem>
        </TooltipTrigger>
        {shouldBeDisabled && (
          <TooltipContent>
            This photo is already set as the primary photo
          </TooltipContent>
        )}
      </Tooltip>
    );
  }

  if (shouldBeDisabled) {
    return null;
  }

  return (
    <IconButton
      type="button"
      variant="secondary"
      icon="fa-star"
      onClick={() => handleSetPrimaryPhoto(file.photo_id)}
    />
  );
};

const PhotoActions = (props: PhotoActionsProps) => {
  console.log({ PhotoActions: props });
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="absolute top-2 right-2">
      {props.asDropdown ? (
        <DropdownMenu onOpenChange={(open) => setMenuOpen(open)}>
          <DropdownMenuTrigger
            className={`${menuOpen ? "opacity-100" : "group-hover:opacity-100 opacity-0"}`}
            asChild
          >
            <IconButton type="button" variant="secondary" icon="fa-ellipsis" />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <SetAsPrimary {...props} asDropdown />
            <DeletePhoto {...props} asDropdown />
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <div className="flex items-center gap-2">
          <SetAsPrimary {...props} />
          <DeletePhoto {...props} />
        </div>
      )}
    </div>
  );
};

export { PhotoActions, SetAsPrimary, DeletePhoto };
