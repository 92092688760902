import { RiskBadgeProps } from "@/components/RiskBadge";
import { DocumentLink } from "@/types/document-types";
import { CrmContact } from "@/types/contact-type";

export type AssetTab = {
  label: string;
  url: string;
  icon: string;
};

export type NewAsset = {
  route_key: string;
  uuid: string;
  parent_id: string;
  code: string;
  name: string;
  contacts?: CrmContact[];
  legacyAsset?: Asset;
};

export type Asset = {
  route_key: string;
  id: string;
  asset_uuid: string;
  model: string;
  asset_id: string;
  asset_code: string;
  asset_name: string;
  asset_display_name: string;
  asset_type: AssetType;
  asset_type_formatted: string;
  asset_type_word: string;
  asset_address?: null;
  asset_suburb?: null;
  asset_postcode?: null;
  asbestos_score: number;
  asbestos_rating: string;
  asbestos_status: string;
  badge_colour: RiskBadgeProps["color"];
  badge_style: RiskBadgeProps["mode"];
  badge_icon: RiskBadgeProps["icon"];
  asset_route: string;
  safety_page_route: string;
  model_id: number;
  asset_photo: string;
  insert_date: string;
  last_update: string;
  parent?: {
    label: string;
    routeKey: string;
    value: string;
  };
  lat: number;
  lng: number;
  asbestos_asset?: {
    asbestosStatus: string;
    recommendedReauditFreq: number;
    recommendedReauditDate: string;
    lastAssessmentDate: string;
    lastAssessmentCompanyName: string;
  };
  document_links?: DocumentLink[];
  document_link?: {
    document_id: number;
    link_id: string;
    published_at: string | null;
  };
};

export const assetTypesMap = {
  organisation: {
    value: "organisation",
    label: "Organisation",
    parent: "",
    class: "App\\Models\\Organisation",
  },
  site: {
    value: "site",
    label: "Site",
    parent: "organisation",
    class: "App\\Models\\Site",
  },
  building: {
    value: "building",
    label: "Building",
    parent: "site",
    class: "App\\Models\\Building",
  },
  level: {
    value: "level",
    label: "Level",
    parent: "building",
    class: "App\\Models\\Level",
  },
} as const;

const assetClasses = Object.values(assetTypesMap).map((x) => x.class);
export type AssetClass = (typeof assetClasses)[number];
export type AssetType = keyof typeof assetTypesMap;

export const assetTypes = Object.values(assetTypesMap) as {
  value: AssetType;
  label: string;
  parent: string;
  class: AssetClass;
}[];
