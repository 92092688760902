import { Fragment, useState } from "react";

import { usePageData } from "@/hooks/usePageData";
import {
  PaginatedResourceFilter,
  PaginatedResourceFilterOption,
} from "@/types";
import { Button, ButtonIconLeft, ButtonIcon, IconButton } from "../ui/button";
import {
  CommandItem,
  Command,
  CommandGroup,
  CommandList,
  CommandInput,
  CommandEmpty,
  CommandSeparator,
} from "../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Checkbox } from "../ui/checkbox";
import pluralize from "pluralize";

export type SelectedFilter = {
  key: string;
  values: string[];
  condition: "equals" | "not_equals";
};

type DataTableFiltersProps = {
  allFilters: PaginatedResourceFilter[];
  filters: SelectedFilter[];
  onFilterChange: (selection: SelectedFilter[]) => void;
};

export const DataTableFilters = ({
  allFilters,
  filters,
  onFilterChange,
}: DataTableFiltersProps) => {
  const {
    platform: { translations },
  } = usePageData();
  const [addingFilter, setAddingFilter] =
    useState<PaginatedResourceFilter | null>(null);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilter[]>(filters);

  const updateFilters = (selection: SelectedFilter[]) => {
    setSelectedFilters(selection);
    onFilterChange(selection);
  };

  const addFilter = (
    key: PaginatedResourceFilter["key"],
    option: PaginatedResourceFilterOption,
  ) => {
    const keyIndex = selectedFilters.findIndex((s) => s.key === key);
    if (keyIndex < 0) {
      updateFilters([
        ...selectedFilters,
        { key, condition: "equals", values: [option.value] },
      ]);
    } else {
      const prevSelection = selectedFilters[keyIndex];
      const newValues = [...prevSelection.values, option.value];
      updateFilters([
        ...selectedFilters.slice(0, keyIndex),
        { ...prevSelection, values: newValues },
        ...selectedFilters.slice(keyIndex + 1),
      ]);
    }
  };

  const removeFilter = (
    key: PaginatedResourceFilter["key"],
    option: PaginatedResourceFilterOption,
  ) => {
    const keyIndex = selectedFilters.findIndex((s) => s.key === key);
    if (keyIndex < 0) {
      return;
    }
    const prevSelection = selectedFilters[keyIndex];
    const newValues = prevSelection.values.filter((v) => v !== option.value);
    updateFilters([
      ...selectedFilters.slice(0, keyIndex),
      { ...prevSelection, values: newValues },
      ...selectedFilters.slice(keyIndex + 1),
    ]);
  };

  const changeFilter = (
    key: PaginatedResourceFilter["key"],
    option: PaginatedResourceFilterOption,
  ) => {
    const keyIndex = selectedFilters.findIndex((s) => s.key === key);
    if (keyIndex < 0) {
      updateFilters([
        ...selectedFilters,
        { key, condition: "equals", values: [option.value] },
      ]);
    } else {
      const prevSelection = selectedFilters[keyIndex];
      const newValues = [option.value];
      updateFilters([
        ...selectedFilters.slice(0, keyIndex),
        { ...prevSelection, values: newValues },
        ...selectedFilters.slice(keyIndex + 1),
      ]);
    }
  };

  const removeEmptyFilters = () => {
    const nonEmptyFilters = selectedFilters.filter((s) => s.values.length);
    updateFilters(nonEmptyFilters);
  };

  const toggleCondition = (selection: SelectedFilter) => {
    const selectionIndex = selectedFilters.findIndex(
      (s) => s.key === selection.key,
    );
    if (selectionIndex >= 0) {
      const nextCondition =
        selection.condition === "equals" ? "not_equals" : "equals";
      updateFilters([
        ...selectedFilters.slice(0, selectionIndex),
        { ...selection, condition: nextCondition },
        ...selectedFilters.slice(selectionIndex + 1),
      ]);
    }
  };

  const getConditionLabel = (selection: SelectedFilter) => {
    if (selection.condition === "equals") {
      if (selection.values.length < 2) {
        return "is";
      } else {
        return "is any of";
      }
    }
    return "is not";
  };

  const clearSelection = (selection: SelectedFilter) => {
    const selectionIndex = selectedFilters.findIndex(
      (s) => s.key === selection.key,
    );
    if (selectionIndex >= 0) {
      updateFilters([
        ...selectedFilters.slice(0, selectionIndex),
        ...selectedFilters.slice(selectionIndex + 1),
      ]);
    }
  };

  const nonAppliedFilters = allFilters.filter(
    (filter) => !selectedFilters.find((s) => s.key === filter.key),
  );

  return (
    <div className="flex flex-row flex-wrap items-center">
      {selectedFilters.map((selection) => {
        const filter = allFilters.find(
          (f) => f.key === selection.key,
        ) as PaginatedResourceFilter;
        return (
          <div
            key={selection.key}
            className="flex flex-row items-center space-x-0.5 mr-2 mb-2"
          >
            <Button
              className="rounded-l rounded-r-none"
              aria-label="filter name"
            >
              {filter.label}
            </Button>
            <Button
              onClick={() => {
                toggleCondition(selection);
              }}
              className="rounded-none"
              aria-label="filter condition"
            >
              {getConditionLabel(selection)}
            </Button>
            <FilterModifier
              removeEmptyFilters={removeEmptyFilters}
              selection={selection}
              selectedFilters={selectedFilters}
              removeFilter={removeFilter}
              addFilter={addFilter}
              changeFilter={changeFilter}
              filter={filter}
            />
            <IconButton
              onClick={() => {
                clearSelection(selection);
              }}
              className="rounded-l-none rounded-r"
              variant="primary"
              icon="fa-xmark"
              aria-label="remove filter"
            />
          </div>
        );
      })}
      {(addingFilter || nonAppliedFilters.length > 0) && (
        <Popover
          onOpenChange={(open) => {
            if (!open) {
              setAddingFilter(null);
              removeEmptyFilters();
            }
          }}
        >
          <PopoverTrigger asChild>
            {selectedFilters.length > 0 ? (
              <Button
                aria-label={translations.filter}
                size={"icon"}
                variant={"tertiary"}
                className="mb-2"
              >
                <ButtonIcon icon="fa-plus" />
              </Button>
            ) : (
              <ButtonIconLeft
                variant={"secondary"}
                icon="fa-filter"
                className="mb-2"
              >
                {translations.filter}
              </ButtonIconLeft>
            )}
          </PopoverTrigger>
          <PopoverContent
            aria-label="filter names"
            align="start"
            className="p-0"
          >
            {!addingFilter && nonAppliedFilters.length > 0 ? (
              <Command>
                <CommandList>
                  <CommandGroup>
                    {nonAppliedFilters.map((filter) => {
                      return (
                        <CommandItem
                          onSelect={() => setAddingFilter(filter)}
                          key={filter.key}
                        >
                          <span>{filter.label}</span>
                        </CommandItem>
                      );
                    })}
                  </CommandGroup>
                </CommandList>
              </Command>
            ) : (
              <>
                {addingFilter && (
                  <Command>
                    <CommandInput
                      className="border-0 focus:ring-0 focus:outline-none"
                      placeholder={addingFilter.label}
                    />
                    <CommandList>
                      <CommandEmpty>No results found.</CommandEmpty>
                      <CommandGroup>
                        {addingFilter.options.map((option) => (
                          <Fragment key={option.value}>
                            <FilterItem
                              option={option}
                              selectedFilters={selectedFilters}
                              addingFilter={addingFilter}
                              changeFilter={changeFilter}
                              addFilter={addFilter}
                              removeFilter={removeFilter}
                            />
                          </Fragment>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                )}
              </>
            )}
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
};

const FilterItem = (props: {
  option: PaginatedResourceFilterOption;
  selectedFilters: SelectedFilter[];
  addingFilter: PaginatedResourceFilter;
  changeFilter: (key: string, option: PaginatedResourceFilterOption) => void;
  addFilter: (key: string, option: PaginatedResourceFilterOption) => void;
  removeFilter: (key: string, option: PaginatedResourceFilterOption) => void;
}) => {
  const isSelected = props.selectedFilters
    .find((s) => s.key === props.addingFilter.key)
    ?.values.includes(props.option.value);
  return (
    <CommandItem
      className="flex items-center gap-2"
      onSelect={() => {
        if (props.addingFilter.singleCondition) {
          props.changeFilter(props.addingFilter.key, props.option);
          return;
        }
        if (isSelected) {
          props.removeFilter(props.addingFilter.key, props.option);
        } else {
          props.addFilter(props.addingFilter.key, props.option);
        }
      }}
      key={props.option.value}
    >
      <Checkbox
        checked={isSelected}
        className={!isSelected ? "opacity-50" : ""}
      />
      <span>{props.option.label}</span>
    </CommandItem>
  );
};

const FilterModifier = ({
  removeEmptyFilters,
  selection,
  selectedFilters,
  removeFilter,
  addFilter,
  changeFilter,
  filter,
}: {
  removeEmptyFilters: () => void;
  selection: SelectedFilter;
  selectedFilters: SelectedFilter[];
  removeFilter: (key: string, option: PaginatedResourceFilterOption) => void;
  addFilter: (key: string, option: PaginatedResourceFilterOption) => void;
  changeFilter: (key: string, option: PaginatedResourceFilterOption) => void;
  filter: PaginatedResourceFilter;
}) => {
  const getOrderedOptions = () => {
    const selectedOptions = filter.options.filter((option) => {
      const isSelected = selectedFilters
        .find((s) => s.key === filter.key)
        ?.values.includes(option.value);
      return isSelected;
    });
    const otherOptions = filter.options.filter((option) => {
      const isSelected = selectedFilters
        .find((s) => s.key === filter.key)
        ?.values.includes(option.value);
      return !isSelected;
    });
    return [...selectedOptions, "line-break", ...otherOptions];
  };

  const [orderedOptions, setOrderedOptions] = useState(getOrderedOptions());

  const getSelectionLabel = (
    selection: SelectedFilter,
    filter: PaginatedResourceFilter,
  ) => {
    if (selection.values.length === 1) {
      const selectedOption = filter.options.find(
        (o) => o.value === selection.values[0],
      );
      return selectedOption?.label;
    }
    return pluralize(filter.label.toLowerCase(), selection.values.length, true);
  };

  return (
    <Popover
      onOpenChange={(open) => {
        if (!open) {
          removeEmptyFilters();
        } else {
          setOrderedOptions(getOrderedOptions());
        }
      }}
    >
      <PopoverTrigger asChild>
        <Button className="rounded-none" aria-label="filter value">
          {getSelectionLabel(selection, filter)}
        </Button>
      </PopoverTrigger>
      <PopoverContent aria-label="filter options" align="start" className="p-0">
        <Command>
          <CommandInput
            className="border-0 focus:ring-0 focus:outline-none"
            placeholder={filter.label}
          />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {orderedOptions.map((option) => {
                const key = typeof option === "string" ? option : option.value;
                return (
                  <Fragment key={key}>
                    {typeof option === "string" ? (
                      <CommandSeparator />
                    ) : (
                      <FilterItem
                        option={option}
                        selectedFilters={selectedFilters}
                        addingFilter={filter}
                        changeFilter={changeFilter}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                      />
                    )}
                  </Fragment>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
