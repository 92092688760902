import { PaginatedResource, PaginatedResourceDisplayFlag } from "@/types";
import {
  DropdownButtonIconLeft,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  Label,
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
  IconButton,
  SelectItem,
  DropdownMenuSeparator,
  Button,
  ScrollArea,
} from "../ui";
import {
  Column,
  ColumnOrderState,
  Table,
  VisibilityState,
} from "@tanstack/react-table";
import {
  closestCenter,
  DragEndEvent,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableDisplayMenuCheckboxItem } from "../SortableDropdownMenu";
import { usePageData } from "@/hooks/usePageData";
import {
  filterColumnCallback,
  setGlobalTableSettingsInStorage,
  setSettingsInStorage,
} from "./utils";
import { PreferenceSwitch } from "../PreferenceSwitch";
import { Icon } from "../Icon";

type DisplayOptionsParams = {
  search: string;
  order_by: string;
  order_direction: string;
  limit: number;
  filters: never[];
  page: number;
  displayFlags: Record<string, "true" | "false" | "">;
};

type DisplayOptionsProps<TData> = {
  updateParam: (key: keyof DisplayOptionsParams, value: unknown) => void;
  params: DisplayOptionsParams;
  tableData: PaginatedResource<TData>;
  table: Table<TData>;
  tableName: string;
};

export const DisplayOptions = <TData extends object>(
  props: DisplayOptionsProps<TData>,
) => {
  const {
    platform: { translations },
  } = usePageData();

  const orderableColumns = props.table
    .getAllLeafColumns()
    .filter((column) => filterColumnCallback(column) && column.getCanSort());

  const tableColumnsForDisplayDropdown = props.table
    .getAllLeafColumns()
    .filter(filterColumnCallback);

  const hasHiddenColumns = tableColumnsForDisplayDropdown.some(
    (column) => !column.getIsVisible(),
  );

  const updateColumnVisibility = (changedVisibility: VisibilityState) => {
    const newColumnVisibility = {
      ...props.table.getState().columnVisibility,
      ...changedVisibility,
    };
    props.table.setColumnVisibility(newColumnVisibility);
    setSettingsInStorage({
      tableName: props.tableName,
      settings: {
        name: "columnVisibility",
        settings: newColumnVisibility,
      },
    });
  };

  const updateColumnOrder = (newColumnOrder: ColumnOrderState) => {
    props.table.setColumnOrder(newColumnOrder);
    setSettingsInStorage({
      tableName: props.tableName,
      settings: {
        name: "columnOrder",
        settings: newColumnOrder,
      },
    });
  };

  const resetColumnVisibility = () => {
    props.table.setColumnVisibility({});
    setSettingsInStorage({
      tableName: props.tableName,
      settings: {
        name: "columnVisibility",
        settings: {},
      },
    });
  };

  const orderBy = props.params.order_by || props.tableData.defaultOrderBy;
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownButtonIconLeft icon={"fa-sliders"} variant="secondary">
          {translations.display}
        </DropdownButtonIconLeft>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        aria-label="display options"
        align="end"
        className="w-72"
      >
        <DropdownMenuLabel className="space-x-2 flex flex-row items-center justify-between">
          <Label className={"w-16 shrink-0"}>{translations.order_by}</Label>
          <div className="flex-1 inline-flex items-center gap-2">
            <Select
              defaultValue={orderBy}
              onValueChange={(column) => {
                setSettingsInStorage({
                  tableName: props.tableName,
                  settings: { name: "order_by", settings: column },
                });
                props.updateParam("order_by", column);
              }}
              disabled={orderableColumns.length === 0}
            >
              <SelectTrigger>
                <SelectValue placeholder="None" />
              </SelectTrigger>
              <SelectContent>
                {orderableColumns.map((column) => {
                  return (
                    <SelectItem key={column.id} value={column.id}>
                      {column.id}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
            {orderBy && (
              <IconButton
                className={"shrink-0"}
                variant={"tertiary"}
                icon={
                  props.params.order_direction === "asc" ||
                  !props.params.order_direction
                    ? "fa-arrow-down-short-wide"
                    : "fa-arrow-down-wide-short"
                }
                onClick={() => {
                  const newOrderDirection =
                    props.params.order_direction === "asc" ||
                    !props.params.order_direction
                      ? "desc"
                      : "asc";
                  setSettingsInStorage({
                    tableName: props.tableName,
                    settings: {
                      name: "order_direction",
                      settings: newOrderDirection,
                    },
                  });
                  props.updateParam("order_direction", newOrderDirection);
                }}
              />
            )}
          </div>
        </DropdownMenuLabel>
        <DropdownMenuLabel className="space-x-2 flex flex-row items-center justify-between">
          <Label className={"w-16 shrink-0"}>{translations.per_page}</Label>
          <div className="flex-1 inline-flex">
            <Select
              defaultValue={String(props.params.limit)}
              onValueChange={(value) => {
                setGlobalTableSettingsInStorage({
                  settingsName: "limit",
                  settings: value,
                });
                props.updateParam("limit", value);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent>
                {props.tableData?.pageLimits.map((limit) => (
                  <SelectItem key={limit} value={String(limit)}>
                    {limit}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {props.tableData.displayFlags?.length > 0 && (
          <>
            <DataTableDisplayFlagsToggle
              displayFlags={props.params.displayFlags}
              allDisplayFlags={props.tableData.displayFlags}
              onDisplayFlagToggle={(key, value) => {
                setSettingsInStorage({
                  tableName: props.tableName,
                  settings: {
                    name: "displayFlags",
                    settings: {
                      ...props.params.displayFlags,
                      [key]: String(value),
                    },
                  },
                });
                props.updateParam("displayFlags", {
                  ...props.params.displayFlags,
                  [key]: String(value),
                });
              }}
            />
            <DropdownMenuSeparator />
          </>
        )}
        <ColumnVisibility
          tableColumnsForDisplayDropdown={tableColumnsForDisplayDropdown}
          hasHiddenColumns={hasHiddenColumns}
          resetColumnVisibility={resetColumnVisibility}
          updateColumnOrder={updateColumnOrder}
          updateColumnVisibility={updateColumnVisibility}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

type DataTableDisplayFlagsToggleProps = {
  displayFlags: Record<string, "true" | "false" | "">;
  allDisplayFlags: PaginatedResourceDisplayFlag[];
  onDisplayFlagToggle: (key: string, value: boolean) => void;
};

const DataTableDisplayFlagsToggle = ({
  displayFlags,
  allDisplayFlags,
  onDisplayFlagToggle,
}: DataTableDisplayFlagsToggleProps) => {
  return (
    <>
      {allDisplayFlags.map((displayFlag) => {
        const selected = displayFlags[displayFlag.key] === "true";
        return (
          <DropdownMenuLabel
            className="flex items-center justify-between"
            key={displayFlag.key}
          >
            <PreferenceSwitch
              label={displayFlag.label}
              helperText={displayFlag.tooltip}
              checked={selected}
              onChange={(checked) =>
                onDisplayFlagToggle(displayFlag.key, checked)
              }
            />
          </DropdownMenuLabel>
        );
      })}
    </>
  );
};

const ColumnVisibility = <TData extends object>(props: {
  tableColumnsForDisplayDropdown: Column<TData, unknown>[];
  resetColumnVisibility: () => void;
  hasHiddenColumns: boolean;
  updateColumnOrder: (newColumnOrder: ColumnOrderState) => void;
  updateColumnVisibility: (changedVisibility: VisibilityState) => void;
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const columnIds = props.tableColumnsForDisplayDropdown.map(
    (column) => column.id,
  );

  const onDragEnd = (event: DragEndEvent) => {
    const { delta, active, over } = event;
    if (active && over) {
      if (active.id === over.id && delta.y === 0) {
        const column = props.tableColumnsForDisplayDropdown.find(
          (c) => c.id === active.id,
        );
        if (column) {
          props.updateColumnVisibility({
            [column.id]: !column.getIsVisible(),
          });
        }
        return;
      }
      if (active.id !== over.id && columnIds) {
        const activeIndex = columnIds.indexOf(active.id as string);
        const overIndex = columnIds.indexOf(over.id as string);

        // If active or over id is not found, return early
        if (activeIndex === -1 || overIndex === -1) {
          return;
        }

        const updatedColumns = arrayMove(columnIds, activeIndex, overIndex);
        props.tableColumnsForDisplayDropdown.forEach((column, index) => {
          if (filterColumnCallback(column)) {
            return null;
          }

          updatedColumns.splice(index, 0, column.id);
        });

        props.updateColumnOrder(updatedColumns);
      }
    }
  };

  return (
    <>
      <div className="w-full flex flex-row items-center justify-between">
        <Label className="text-sm font-medium px-1">Column</Label>
        <Button
          variant={"tertiary"}
          onClick={props.resetColumnVisibility}
          className={!props.hasHiddenColumns ? "opacity-0 cursor-default" : ""}
        >
          Reset
        </Button>
      </div>
      <ScrollArea
        className={`${props.tableColumnsForDisplayDropdown.length > 8 ? "h-72" : ""} px-1`}
      >
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={onDragEnd}
          sensors={sensors}
        >
          <SortableContext
            items={columnIds}
            strategy={verticalListSortingStrategy}
          >
            {props.tableColumnsForDisplayDropdown.map((column) => {
              return (
                <SortableDisplayMenuCheckboxItem
                  rowKey={column.id}
                  key={column.id}
                  checked={column.getIsVisible()}
                >
                  <Icon
                    icon="fa-grip-vertical"
                    iconStyle="SOLID"
                    className="text-muted-foreground opacity-0 transition-opacity duration-75 group-hover:opacity-100 cursor-grab"
                  />
                  <span>{column.id}</span>
                </SortableDisplayMenuCheckboxItem>
              );
            })}
          </SortableContext>
        </DndContext>
      </ScrollArea>
    </>
  );
};
